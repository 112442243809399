<template>
  <div class="apply">
    <van-notice-bar :scrollable="false" class="notice" color="#1989fa" background="#ecf9ff"> 你正在向{{ companyName }}申请开票 </van-notice-bar>
    <van-cell-group>
      <van-cell title="申请来源" :value="source" icon="shop-o" />
      <van-cell v-if="amount > 0" title="开票金额" :value="currency(amount)" icon="balance-list-o" />
    </van-cell-group>
    <van-cell-group class="apply-mt15">
      <van-field label="开票类型">
        <template #input>
          <van-radio-group v-model="invoiceType" direction="horizontal">
            <van-radio name="企业">公司/单位</van-radio>
            <van-radio name="个人">个人</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field required label="开票名称" :placeholder="invoiceType === '企业' ? '公司或者单位名称' : '个人姓名'" v-model="name" class="field-button">
        <template #button v-if="invoiceType === '企业'">
          <van-button size="mini" type="primary" v-if="name" @click="query_en"> 查询 </van-button>
          <van-button size="mini" type="primary" v-if="!name" @click="wxtitle"> 微信抬头 </van-button>
        </template>
      </van-field>
      <van-field clearable label="公司税号" placeholder="公司统一社会信用代码" v-if="invoiceType === '企业'" v-model="identity" />
      <van-collapse v-model="more" v-if="invoiceType === '企业'">
        <van-collapse-item title="更多开票信息（选填）">
          <van-field label="公司电话" placeholder="公司联系电话" v-model="tel" />
          <van-field label="公司地址" placeholder="公司注册地址" v-model="address" />
          <van-field label="开户银行" placeholder="公司开户银行" v-model="bankName" />
          <van-field label="银行账号" placeholder="公司银行账号" v-model="bankAccount" />
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>
    <van-cell-group class="apply-mt15">
      <van-field required label="联系方式" placeholder="常用的手机或电话" v-model="contactTel" />
      <van-field label="Email" type="email" v-model="email" placeholder="接收电子发票的邮箱" />
      <van-field label="备注" v-model="remark" placeholder="填写开票备注信息" />
    </van-cell-group>
    <div class="apply-submit">
      <van-button block type="primary" native-type="submit" @click="apply_create"> 提 交 </van-button>
    </div>
    <van-action-sheet v-model:show="enshow" :actions="ens" close-on-click-action safe-area-inset-bottom :cancel-text="cancel" @opened="en_opened" @select="en_selected" />
  </div>
</template>

<script>
import { NoticeBar, Field, Cell, CellGroup, RadioGroup, Radio, Collapse, CollapseItem, Button, Toast, ActionSheet, Notify } from "vant";

import apply from "../service/ApplyService";
import util from "../util";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      key: null,
      name: null,
      source: null,
      companyName: null,
      amount: "0.00",
      invoiceType: "企业",
      email: null,
      tel: null,
      address: null,
      bankName: null,
      bankAccount: null,
      contactTel: null,
      loading: true,
      openid: null,
      identity: null,
      more: [],
      ens: [],
      enshow: false,
      cancel: null,
      remark: null,
    };
  },
  async mounted() {
    let key = this.$route.params.key;
    let code = this.$route.query.code;

    // 获取开票申请Key信息
    let info = await apply.info(key);
    if (info.status !== true || !info.data) {
      this.$router.replace(util.reult(info));
      return;
    }

    this.key = key;
    this.source = info.data.source || info.data.companyAlias;
    this.amount = info.data.amount;
    this.companyName = info.data.companyName;
    this.contactTel = info.data.contactTel;

    // 配置微信JsSdk
    apply.wxconfig(info.data.appId).then(wx.config);
    // 禁用菜单
    wx.ready(wx.hideAllNonBaseMenuItem);

    // 获取当前登录微信用户
    let user = await apply.user(code, key);
    if (user.status !== true) {
      this.$router.replace(util.reult(user));
      return;
    }

    this.openid = user.data.openId;

    // 获取用户最后的开票信息
    let last = await apply.last(user.data.openId);
    if (last.status) {
      let data = last.data;
      this.name = data.name;
      this.invoiceType = data.invoiceType;
      this.email = data.email;
      if (!this.contactTel) {
        this.contactTel = data.contactTel;
      }
      this.bankName = data.bankName;
      this.bankAccount = data.bankAccount;
      this.tel = data.tel;
      this.address = data.address;
      this.identity = data.identity;
    }
  },
  methods: {
    wxtitle() {
      wx.invoke("chooseInvoiceTitle", { scene: "1" }, this.wx_title_selected);
    },
    wx_title_selected(res) {
      if (res.err_msg === "chooseInvoiceTitle:ok") {
        var wxt = JSON.parse(res.choose_invoice_title_info);
        this.name = wxt.title;
        this.identity = wxt.taxNumber;
        this.tel = wxt.telephone;
        this.address = wxt.companyAddress;
        this.bankName = wxt.bankName;
        this.bankAccount = wxt.bankAccount;
      }
    },
    currency: (val) => util.tc(val),
    query_en() {
      let toast = Toast.loading({
        message: "正在查询...",
        forbidClick: true,
      });
      this.ens = [];
      apply.query(this.name).then((res) => {
        if (res && res.data && res.data.length) {
          for (let row of res.data) {
            this.ens.push(row);
          }
          this.cancel = this.ens.length < 3 ? "取消" : null;
          this.enshow = true;
        } else {
          this.enshow = false;
        }
        toast.clear();
      });
    },
    en_opened() {
      let aname = new Set(this.name);
      let btns = document.getElementsByClassName("van-action-sheet__name");
      for (let btn of btns) {
        let text = btn.innerText;
        for (let key of aname) {
          text = text.replace(new RegExp(`(${key})`, "g"), `<i>$1</i>`);
        }
        btn.innerHTML = text;
        console.log(text);
      }
    },
    en_selected(obj) {
      if (obj) {
        // 如果选择的公司不是当前填充的公司，则需要重置银行、地址等信息
        if (this.name != obj.name) {
          this.address = null;
          this.tel = null;
          this.bankName = null;
          this.bankAccount = null;
        }
        this.name = obj.name;
        if (obj.identity) {
          this.identity = obj.identity;
          return;
        }
        apply.geten(obj.id).then((res) => {
          if (res.status) {
            this.name = res.data.name;
            this.identity = res.data.identity;
          }
        });
      }
    },
    apply_create() {
      let toast = Toast.loading({
        message: "正在提交...",
        forbidClick: true,
      });
      util.http("apply/update", this.$data).then((res) => {
        if (res.status) {
          this.$router.replace(`/result/${res.data.key}`);
        } else {
          Notify({ type: "warning", message: res.msg });
        }
        toast.clear();
      });
    },
  },
  components: {
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [RadioGroup.name]: RadioGroup,
    [CellGroup.name]: CellGroup,
    [Radio.name]: Radio,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [ActionSheet.name]: ActionSheet,
    [Notify.Component.name]: Notify.Component,
  },
};
</script>

<style lang="less">
.apply {
  &-mt15 {
    margin-top: 15px;
  }

  &-submit {
    margin: 10px;
  }

  .van-button--mini {
    height: 22px;
  }

  .van-field__button {
    line-height: initial;
  }

  .van-action-sheet__name i {
    text-decoration: none;
    font-style: normal;
    color: red;
  }
}
.notice > div {
  justify-content: center;
}
</style>
